import dateFormat from 'dateformat';
import moment from 'moment';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import {
  createStripeAccount,
  fetchPartnershipOrganizations,
  patchAdminRamUser,
  patchDsoManager,
  patchDsoName,
  updateCurrentUser,
  updateDsoRamManager,
} from '../../../actions/userActions';
import { createLoadingSelector } from '../../../apis/selectors';
import { officeTypes } from '../../../constants';
import { getUserEducation } from '../../../actions/licensesAction';
import { isBoolean } from '../../../utils';
import LineInfo from '../../commonComponents/LineInfo/LineInfo';
import NotificationCard from './NotificationCard';
import ProfileAddressInfo from './ProfileAddressInfo';
import { DSORamManagerSelector } from './DSORamManagerSelector';
import { ProductivityGroup } from '../../commonComponents/LineInfo/components/ProductivityGroup/ProductivityGroup';
import { UserProfession } from '../../../enums/UserProfession';
import { isObjectEqual } from '../../../utils/Object';
import { DsoName } from './components/DsoName/DsoName';
import { shouldUseDsoStructure } from '../../../growthbook';

const phoneFormatter = require('phone-formatter');

class ProfileDentistGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      office_notes: props.currentUser?.notes_for_temp || '',
      allowedRequest: props.currentUser?.allowedRequest,
      productivity: {
        operatories: props.currentUser?.office_info?.productivity?.operatories || null,
        permanentStaff: {
          [UserProfession.RDH]:
            props.currentUser?.office_info?.productivity?.permanentStaff?.RDH || null,
          [UserProfession.DA]:
            props.currentUser?.office_info?.productivity?.permanentStaff?.DA || null,
          [UserProfession.DN]:
            props.currentUser?.office_info?.productivity?.permanentStaff?.DN || null,
        },
        bookedOutPatients: props.currentUser?.office_info?.productivity?.bookedOutPatients || null,
      },
    };
  }

  componentDidMount() {
    const {
      fetchPartnershipOrganizations,
      getUserEducation,
      partnershipOrganizations,
      userEducation,
    } = this.props;

    if (!partnershipOrganizations?.length) {
      fetchPartnershipOrganizations();
    }

    if (!userEducation) {
      getUserEducation({ userType: 'DNT' });
    }
  }

  handleNotesChange = (office_notes) => {
    this.setState({ office_notes });
  };

  renderDentistAdminInfo = () => {
    const { currentUser } = this.props;
    const { editProfile } = this.state;

    let positionAdmin = '';
    let titleAdmin = '';
    let emailAdmin = '';
    let firstNmaeAdmin = '';
    let lastNmaeAdmin = '';
    let contactNumberAdmin = '';

    if (currentUser.account_admin.length !== 0) {
      const { position, title, email_id, first_name, last_name, contact_number } =
        currentUser.account_admin[0];

      positionAdmin = position;
      titleAdmin = title;
      emailAdmin = email_id;
      firstNmaeAdmin = first_name;
      lastNmaeAdmin = last_name;
      contactNumberAdmin = contact_number;
    }

    return (
      <div>
        <LineInfo
          isEdit={editProfile}
          title="Position"
          onDidChange={(val) => this.setState({ account_admin_position: val })}
          info={this.state.editProfile ? this.state.account_admin_position : positionAdmin}
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ account_admin_title: val })}
          title="Title"
          info={this.state.editProfile ? this.state.account_admin_title : titleAdmin}
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ accountName: val })}
          title="Name"
          info={
            this.state.editProfile
              ? this.state.accountName
              : `${firstNmaeAdmin || ''} ${lastNmaeAdmin || ''}`
          }
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ main_office_number: val })}
          title="Contact Number"
          info={this.state.editProfile ? this.state.main_office_number : contactNumberAdmin}
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ account_admin_email_id: val })}
          title="Contact Email"
          info={this.state.editProfile ? this.state.account_admin_email_id : emailAdmin}
        />
      </div>
    );
  };

  renderOfficeInfo() {
    const { currentUser } = this.props;
    const { parkingInformation } = currentUser;
    const {
      officeType,
      timePerPatientRDH,
      timePerPatientDA,
      timePerPatientDN,
      isAssistedHygiene,
      softwares,
      insurances,
      productivity,
    } = currentUser.office_info;
    const { editProfile } = this.state;

    return (
      <div>
        <LineInfo
          isEdit={editProfile}
          title="DSO or Private Practice"
          info={officeType ? officeTypes.find(({ value }) => value === officeType)?.title : '-'}
          onDidChange={(val) => this.setState({ officeType: val })}
          isDDL
          ddlValue={this.state.officeType}
        />
        <ProductivityGroup
          isEdit={editProfile}
          onChange={(value) => this.setState(value)}
          productivity={this.state.productivity}
          defaultProductivity={productivity}
        />
        <LineInfo
          isEdit={editProfile}
          title="Time per patient (RDH)"
          info={editProfile ? this.state.timePerPatientRDH : timePerPatientRDH || '-'}
          onDidChange={(val) => this.setState({ timePerPatientRDH: val })}
          isTimePerPatient
        />
        <LineInfo
          isEdit={editProfile}
          title="Time per patient (DA)"
          info={editProfile ? this.state.timePerPatientDA : timePerPatientDA || '-'}
          onDidChange={(val) => this.setState({ timePerPatientDA: val })}
          isTimePerPatient
        />
        <LineInfo
          isEdit={editProfile}
          title="Time per patient (DN)"
          info={editProfile ? this.state.timePerPatientDN : timePerPatientDN || '-'}
          onDidChange={(val) => this.setState({ timePerPatientDN: val })}
          isTimePerPatient
        />
        <LineInfo
          isEdit={editProfile}
          title="Assisted Hygiene (RDH)"
          info={editProfile ? this.state.isAssistedHygiene : isAssistedHygiene}
          onDidChange={(val) => this.setState({ isAssistedHygiene: val })}
          isAssistedHygiene
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ insurances: val })}
          isInsurance
          title="Insurance Provider"
          info={
            editProfile
              ? this.state.insurances || insurances || []
              : this.state.insurances || insurances || '-'
          }
        />
        <LineInfo
          isEdit={editProfile}
          onDidChange={(val) => this.setState({ softwares: val })}
          isSoftware
          title="Softwares"
          info={
            editProfile
              ? this.state.softwares || softwares || []
              : this.state.softwares || softwares || '-'
          }
        />
        <LineInfo
          isEdit={editProfile}
          title="Parking"
          info={
            editProfile
              ? `$${this.state.parking_cost} / ${this.state.parking_cost_type}`
              : `$${parkingInformation?.cost || ''} / ${parkingInformation?.costType || ''}`
          }
          onDidChange={(val) =>
            this.setState({
              is_parking_free: val.is_parking_free,
              parking_cost: val.parking_cost,
              parking_cost_type: val.parking_cost_type,
            })
          }
          parkingInformation={{
            is_parking_free: this.state.is_parking_free,
            parking_cost: this.state.parking_cost,
            parking_cost_type: this.state.parking_cost_type,
          }}
          isParkingCost
        />
        <LineInfo
          isEdit={editProfile}
          title="Parking Type"
          info={editProfile ? this.state.parking_type : parkingInformation?.parkingType || '-'}
          onDidChange={(val) => this.setState({ parking_type: val })}
          isParkingType
        />
      </div>
    );
  }

  handleCreateStripeAccount = async () => {
    const { currentUser, createStripeAccount } = this.props;
    createStripeAccount(currentUser?.id);
  };

  renderDentistInfo() {
    const { currentUser, currentUserStripeId } = this.props;
    return (
      <div>
        <LineInfo
          isEdit={this.state.editProfile}
          title="Office Name"
          info={this.state.editProfile ? this.state.office_name : currentUser.office_name}
          onDidChange={(val) => this.setState({ office_name: val })}
        />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Doctor First Name"
          info={this.state.editProfile ? this.state.first_name : currentUser.first_name}
          onDidChange={(val) => this.setState({ first_name: val })}
        />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Doctor Last Name"
          info={this.state.editProfile ? this.state.last_name : currentUser.last_name}
          onDidChange={(val) => this.setState({ last_name: val })}
        />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Email"
          info={this.state.editProfile ? this.state.email_id : currentUser.email_id}
          onDidChange={(val) => this.setState({ email_id: val })}
        />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Contact"
          info={
            this.state.editProfile
              ? this.state.contact_number
              : currentUser.contact_number
                ? phoneFormatter.format(currentUser.contact_number, '(NNN) NNN-NNNN')
                : ''
          }
          onDidChange={(val) => this.setState({ contact_number: val })}
        />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Web site"
          info={this.state.editProfile ? this.state.web_site : currentUser.web_site}
          onDidChange={(val) => this.setState({ web_site: val })}
        />
        <LineInfo
          title="Covid T&C"
          info={
            currentUser.covid_tc_acceptance
              ? dateFormat(currentUser.covid_tc_acceptance, 'ddd, mmmm dS, yyyy')
              : '-'
          }
        />
        <LineInfo
          title="Last time sign in"
          info={
            currentUser.last_time_sign_in
              ? dateFormat(currentUser.last_time_sign_in, 'ddd, mmmm dS, yyyy')
              : '-'
          }
        />

        <div
          className="container"
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p className="col-6" style={{ fontWeight: '600' }}>
            Stripe account
          </p>
          <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {currentUserStripeId ? (
              <a
                href={`https://dashboard.stripe.com/customers/${currentUserStripeId}` || '-'}
                className="btn btn-success"
                target="_blank"
                rel="noreferrer"
              >
                Open Stripe
              </a>
            ) : this.props.isStripeAccountLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 20,
                  width: 20,
                  marginLeft: 20,
                }}
              />
            ) : (
              <button
                className="btn btn-success"
                style={{
                  alignSelf: 'center',
                  marginBottom: 10,
                }}
                onClick={() => this.handleCreateStripeAccount()}
              >
                Create Stripe Account
              </button>
            )}
          </div>
        </div>

        <LineInfo title="App version" info={currentUser.client_version || '-'} />

        <LineInfo title="Platform" info={currentUser.device_type || '-'} />
        <LineInfo
          isEdit={this.state.editProfile}
          title="Admin User"
          info={this.state.editProfile ? this.state.isAdmin : currentUser?.isAdmin}
          onDidChange={(val) => this.setState({ isAdmin: val === 'true' })}
          isAdmin
        />

        <LineInfo
          title="Worker Type"
          info={currentUser.workerClassification?.classificationType || '-'}
        />

        <LineInfo
          title="Worker Type Selected by"
          info={currentUser.workerClassification?.setPerformedBy?.full_name || '-'}
        />

        <LineInfo
          title="Worker Type Selected date"
          info={
            currentUser.workerClassification?.setPerformedAt
              ? dateFormat(currentUser.workerClassification?.setPerformedAt, 'ddd, mmmm dS, yyyy')
              : '-'
          }
        />
        <LineInfo
          title="Terms & Conditions accepted"
          info={currentUser.isTermsAccepted ? 'Yes' : 'No'}
        />
        <LineInfo
          title="Terms of Service accepted"
          info={
            isBoolean(currentUser.office_info?.gotuTerms?.accepted)
              ? currentUser.office_info?.gotuTerms?.accepted
                ? 'Yes'
                : 'No'
              : '-'
          }
        />
        <LineInfo
          title="Who accepted Terms of Service"
          info={
            currentUser.office_info?.gotuTerms?.author ? (
              <a
                style={{
                  all: 'unset',
                  cursor: 'pointer',
                }}
                href={`/user/dentist/${currentUser.office_info?.gotuTerms?.author}`}
                target="_blank"
                rel="noreferrer"
              >
                View Profile
              </a>
            ) : (
              '-'
            )
          }
        />
        <LineInfo
          title="Time Terms of Service was accepted"
          info={
            currentUser.office_info?.gotuTerms?.updatedAt
              ? moment(currentUser.office_info?.gotuTerms?.updatedAt).format('MM/DD/YYYY')
              : '-'
          }
        />
        <LineInfo
          title="Personal Service Agreement accepted"
          info={
            currentUser.workerClassification?.classificationType === 'contractor'
              ? 'Not needed'
              : isBoolean(currentUser.office_info?.psaTerms?.accepted)
                ? currentUser.office_info?.psaTerms?.accepted
                  ? 'Yes'
                  : 'No'
                : '-'
          }
        />
        <LineInfo
          title="Who accepted Personal Service Agreement"
          info={
            currentUser.office_info?.psaTerms?.author ? (
              <a
                style={{
                  all: 'unset',
                  cursor: 'pointer',
                }}
                href={`/user/dentist/${currentUser.office_info?.psaTerms?.author}`}
                target="_blank"
                rel="noreferrer"
              >
                View Profile
              </a>
            ) : (
              '-'
            )
          }
        />
        <LineInfo
          title="Time Personal Service Agreement was accepted"
          info={
            currentUser.office_info?.psaTerms?.updatedAt
              ? moment(currentUser.office_info?.psaTerms?.updatedAt).format('MM/DD/YYYY')
              : '-'
          }
        />
      </div>
    );
  }

  enableEdit = () => {
    const {
      office_name,
      first_name,
      last_name,
      email_id,
      contact_number,
      web_site,
      isAdmin,
      office_info,
      account_admin,
      allowedRequest,
      parkingInformation,
    } = this.props.currentUser;

    this.setState({
      editProfile: true,
      office_name,
      first_name,
      last_name,
      email_id,
      contact_number,
      web_site,
      isAdmin,
      account_admin_position: account_admin[0]?.position || '',
      account_admin_title: account_admin[0]?.title || '',
      account_admin_email_id: account_admin[0]?.email_id || '',
      accountName: `${account_admin[0]?.first_name || ''} ${account_admin[0]?.last_name || ''}`,
      main_office_number: account_admin[0]?.contact_number || '',
      timePerPatientRDH: office_info.timePerPatientRDH,
      timePerPatientDA: office_info.timePerPatientDA,
      timePerPatientDN: office_info.timePerPatientDN,
      isAssistedHygiene: office_info.isAssistedHygiene,
      softwares: office_info.education?.softwares || [],
      insurances: office_info.insurances,
      officeType: office_info.officeType,
      is_parking_free: parkingInformation?.isParkingFree,
      parking_cost: parkingInformation?.cost,
      parking_cost_type: parkingInformation?.costType || 'hr',
      parking_type: parkingInformation?.parkingType,
      allowedRequest,
      productivity: {
        operatories: office_info.productivity?.operatories || null,
        permanentStaff: {
          [UserProfession.RDH]: office_info.productivity?.permanentStaff?.RDH || null,
          [UserProfession.DA]: office_info.productivity?.permanentStaff?.DA || null,
          [UserProfession.DN]: office_info.productivity?.permanentStaff?.DN || null,
        },
        bookedOutPatients: office_info.productivity?.bookedOutPatients || null,
      },
    });
  };

  submitNotes = () => {
    const { office_notes } = this.state;
    this.props.updateCurrentUser({ notes_for_temp: office_notes });
  };

  saveEditProfile = () => {
    const {
      office_name,
      first_name,
      last_name,
      main_office_number,
      email_id,
      contact_number,
      web_site,
      isAdmin,
      timePerPatientRDH,
      timePerPatientDA,
      timePerPatientDN,
      isAssistedHygiene,
      softwares,
      insurances,
      officeType,
      accountName,
      account_admin_title,
      account_admin_email_id,
      account_admin_position,
      is_parking_free,
      parking_cost,
      parking_cost_type,
      parking_type,
      productivity,
    } = this.state;
    const { currentUser, parkingInformation } = this.props;

    const office_info = {
      education: {
        ...currentUser?.office_info?.education,
        softwares,
      },
      insurances,
      timePerPatientRDH,
      timePerPatientDA,
      timePerPatientDN,
      isAssistedHygiene,
      officeType,
    };

    const costInformation = {
      isParkingFree: is_parking_free,
      cost: Number(parking_cost),
      costType: parking_cost_type,
    };

    const parkingInfo =
      !is_parking_free && !parking_cost && !parking_type
        ? {}
        : {
          parkingInformation: {
            ...costInformation,
            parkingType: parking_type,
          },
        };

    if (!is_parking_free || !parking_cost || !parking_type) {
      this.setState({
        is_parking_free: parkingInformation?.isParkingFree,
        parking_cost: parkingInformation?.cost,
        parking_type: parkingInformation?.parkingType,
      });
    }

    const officeProductivity = {
      operatories: currentUser?.office_info?.productivity?.operatories || null,
      permanentStaff: {
        [UserProfession.RDH]: currentUser?.office_info?.productivity?.permanentStaff?.RDH || null,
        [UserProfession.DA]: currentUser?.office_info?.productivity?.permanentStaff?.DA || null,
        [UserProfession.DN]: currentUser?.office_info?.productivity?.permanentStaff?.DN || null,
      },
      bookedOutPatients: currentUser?.office_info?.productivity?.bookedOutPatients || null,
    };

    this.props.updateCurrentUser({
      office_name,
      first_name,
      last_name,
      email_id,
      contact_number,
      web_site,
      isAdmin,
      office_info,
      // address: this.props.currentUser.address,
      account_admin_title,
      account_admin_email_id,
      account_admin_contact_number: main_office_number,
      account_admin_first_name: accountName.split(' ')[0],
      account_admin_last_name: accountName.split(' ')[1],
      account_admin_position,
      ...(!isObjectEqual(officeProductivity, productivity) && { productivity }),
      ...parkingInfo,
    });
    this.setState({ editProfile: false });
  };

  renderOfficeManager = () => {
    const { currentUser, ramUsers, patchAdminRamUser } = this.props;
    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            RAM
          </h3>
          <select
            className="form-control"
            value={currentUser?.ram === null ? 'unassigned' : currentUser?.ram}
            onChange={(e) => patchAdminRamUser(e.target.value)}
          >
            <option value="unassigned">None</option>
            {ramUsers?.map((ram) => (
              <option value={ram.id}>
                {ram.first_name} {ram.last_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  renderDsoManager = () => {
    const { currentUser, dsoManagers, patchDsoManager } = this.props;

    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            DSO Manager
          </h3>
          <select
            className="form-control"
            value={currentUser?.dsoManager === null ? 'unassigned' : currentUser?.dsoManager}
            onChange={(e) => patchDsoManager(e.target.value)}
          >
            <option value="unassigned">None</option>
            {dsoManagers?.map((dsoManager) => (
              <option value={dsoManager.id}>
                {dsoManager.first_name} {dsoManager.last_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  renderCancellationPolicy = () => {
    const { currentUser, cancellationPolicies, updateCurrentUser } = this.props;

    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            Cancellation Policy
          </h3>
          <select
            className="form-control"
            value={
              currentUser?.cancellationPolicy
                ? currentUser?.cancellationPolicy
                : cancellationPolicies?.find(({ title }) =>
                  title?.toLowerCase()?.includes('default'),
                )?.id
            }
            onChange={(e) => updateCurrentUser({ cancellationPolicy: e.target.value })}
          >
            {cancellationPolicies?.map((cancellationPolicy) => (
              <option value={cancellationPolicy.id}>{cancellationPolicy.title}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  renderDsoName = () => {
    const { currentUser, dsoNames, patchDsoName } = this.props;

    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            DSO Name
          </h3>
          <select
            className="form-control"
            value={currentUser?.dsoName === null ? 'unassigned' : currentUser?.dsoName}
            onChange={(e) => patchDsoName(e.target.value === 'unassigned' ? null : e.target.value)}
          >
            <option value="unassigned">None</option>
            {dsoNames?.map((dsoName) => (
              <option value={dsoName.code}>{dsoName.name}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  renderRequestControls = () => {
    const { updateCurrentUser } = this.props;
    const { allowedRequest } = this.state;

    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            Request Controls
          </h3>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
            <input
              className="react-switch-checkbox mr-2"
              style={{ margin: 0 }}
              type="checkbox"
              onChange={() =>
                this.setState({
                  allowedRequest: {
                    ...allowedRequest,
                    tempOrMdbShifts: !allowedRequest?.tempOrMdbShifts,
                  },
                })
              }
              checked={allowedRequest?.tempOrMdbShifts}
            />

            <p style={{ marginBottom: 0 }}>Able to request Temp Shifts</p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className="react-switch-checkbox mr-2"
              style={{ margin: 0 }}
              type="checkbox"
              onChange={() =>
                this.setState({
                  allowedRequest: {
                    ...allowedRequest,
                    ppJob: !allowedRequest?.ppJob,
                  },
                })
              }
              checked={allowedRequest?.ppJob}
            />

            <p style={{ marginBottom: 0 }}>Able to request Permanent Placement</p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className="react-switch-checkbox mr-2"
              style={{ margin: 0 }}
              type="checkbox"
              onChange={() =>
                this.setState({
                  allowedRequest: {
                    ...allowedRequest,
                    locumTenensDentist: !allowedRequest?.locumTenensDentist,
                  },
                })
              }
              checked={allowedRequest?.locumTenensDentist}
            />

            <p style={{ marginBottom: 0 }}>Able to request Locum Tenens Dentist</p>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-success"
          style={{
            alignSelf: 'center',
            marginBottom: 10,
          }}
          onClick={() => {
            updateCurrentUser({
              allowedRequest,
            });
          }}
        >
          Save
        </button>
      </div>
    );
  };

  renderPartnershipCodeProgram = () => {
    const { currentUser, updateCurrentUser, partnershipOrganizations } = this.props;

    const statusColor = {
      eligible: '#5cb85c',
      used: '#d9534f',
    };

    return (
      <div className="card mb-2">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            Partnership Code Program
          </h3>

          <p className="global_font">
            Status:{' '}
            <span
              style={{
                color: statusColor[currentUser?.partnerReferral?.status],
                textTransform: 'capitalize',
              }}
            >
              {currentUser?.partnerReferral?.status || 'None'}
            </span>
          </p>

          <p className="global_font">Affiliated Organization:</p>

          <select
            className="form-control"
            value={
              currentUser?.partnerReferral === null
                ? 'unassigned'
                : currentUser?.partnerReferral?.partnershipCode?.code
            }
            onChange={(event) => {
              const code = event.target.value;
              const organization = partnershipOrganizations?.find(
                (item) => item.code === code,
              )?.organization;

              updateCurrentUser({ partnerReferral: { code, organization } });
            }}
          >
            <option value="unassigned">None</option>
            {partnershipOrganizations?.map(({ organization, code }) => (
              <option value={code}>{organization}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  render() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return <div>Loading</div>;
    }

    return (
      <div>
        <div className="card-deck mt-3" style={{ width: '70vw' }}>
          <div className="card" style={{ minWidth: '500px' }}>
            <div className="card-body" style={{ padding: '20px 50px' }}>
              <h3 className="card-title" style={{ textAlign: 'center', marginTop: 34, marginBottom: 30 }}>
                Account Info
              </h3>
              {this.renderDentistInfo()}

              <h3 className="card-title" style={{ textAlign: 'center' }}>
                Account Admin
              </h3>
              {this.renderDentistAdminInfo()}
              <h3 className="card-title" style={{ textAlign: 'center' }}>
                Office Info
              </h3>
              {this.renderOfficeInfo()}
              <div
                style={{
                  width: '100%',
                  padding: '10px 0px',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                {this.state.editProfile ? (
                  ''
                ) : (
                  <button
                    className="btn btn-info"
                    style={{ width: '100px' }}
                    onClick={(_) => this.enableEdit()}
                  >
                    Edit
                  </button>
                )}

                {!this.state.editProfile ? (
                  ''
                ) : (
                  <button
                    className="btn btn-secondary"
                    style={{
                      width: '100px',
                    }}
                    onClick={(_) => this.setState({ editProfile: false })}
                  >
                    Cancel
                  </button>
                )}

                {!this.state.editProfile ? (
                  ''
                ) : (
                  <button
                    className="btn btn-success"
                    style={{
                      width: '100px',
                    }}
                    onClick={() => this.saveEditProfile()}
                  >
                    Save
                  </button>
                )}
              </div>
              <ProfileAddressInfo />

              <div
                style={{
                  width: '100%',
                  padding: '10px 0px',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                {currentUser.is_verified_user ? (
                  ''
                ) : (
                  <button
                    className="btn btn-success"
                    style={{
                      width: '100px',
                      margin: '0',
                    }}
                    onClick={this.props.openVerify}
                  >
                    Verify
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="card-deck ml-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '400px',
            }}
          >
            {this.renderOfficeManager()}
            {this.renderDsoManager()}
            <DSORamManagerSelector />
            {this.renderDsoName()}

            {shouldUseDsoStructure() && <DsoName officeId={currentUser.id} />}

            {this.renderCancellationPolicy()}
            {this.renderRequestControls()}
            <div className="card">
              <div
                className="card-body"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                }}
              >
                <h3 className="card-title" style={{ textAlign: 'center' }}>
                  Office Notes
                </h3>

                <textarea
                  style={{
                    fontFamily: 'Nunito-Regular',
                    height: 128,
                    marginTop: 10,
                    marginBottom: '20px',
                  }}
                  placeholder="Put your office notes here"
                  delay={1000}
                  value={this.state.office_notes}
                  onChange={(event) => this.handleNotesChange(event.target.value)}
                />

                <button
                  className="btn btn-success"
                  style={{
                    alignSelf: 'center',
                    marginBottom: 10,
                  }}
                  onClick={this.submitNotes}
                >
                  Submit
                </button>
              </div>
            </div>

            {this.renderPartnershipCodeProgram()}

            <div className="card mt-2">
              <div className="card-body">
                <NotificationCard />
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: '100px' }} />
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['CREATE_STRIPE_ACCOUNT']);

const mapStateToProps = (state) => ({
  currentUserStripeId: state.users.currentUserStripeId,
  currentUser: state.users.currentUser,
  userEducation: state.users.userEducation,
  ramUsers: state.users.ramUsers,
  dsoNames: state.users.dsoNames,
  cancellationPolicies: state.users.cancellationPolicies,
  partnershipOrganizations: state.users.partnershipOrganizations,
  dsoManagers: state.users.dsoManagers,
  isPayoutUser: state.users.isPayoutUser,
  dsoRAMManagers: state.users.dsoRAMManagers,
  isStripeAccountLoading: loadingSelector(state),
});

export default connect(mapStateToProps, {
  updateCurrentUser,
  patchAdminRamUser,
  createStripeAccount,
  patchDsoManager,
  patchDsoName,
  fetchPartnershipOrganizations,
  getUserEducation,
  updateDsoRamManager,
})(ProfileDentistGeneralInfo);
